<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <!--  -->
    <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="warning"
          icon="mdi-transfer"
          title="Transaksi"
          :value="String(Sumarry.total)"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="rgb(233, 30, 99)"
          icon="mdi-cart-plus"
          title="Transaksi Hari Ini"
          :value="String(Sumarry.now)"
          style="cursor: pointer;"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="success"
          icon="mdi-check-all"
          title="Transaksi Berhasil"
          :value="String(Sumarry.success)"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-account-convert"
          title="Transaksi Gagal"
          :value="String(Sumarry.failed)"
        />
      </v-col>
    </v-row>
    <!--  -->
    <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="2"
      >
        <v-autocomplete
          v-model="community_id"
          prepend-icon="mdi-account-multiple"
          :items="community"
          item-text="name"
          item-value="id"
          :search-input.sync="community_search"
          label="Komunitas"
          :loading="isLoadingCommunity"
        >
          <!--  -->
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="2"
      >
        <v-dialog
          ref="start_menu"
          v-model="start_menu"
          :return-value.sync="start_date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="start_date"
              label="Tanggal Awal"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="start_date"
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="start_menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.start_menu.save(start_date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <!--  -->
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="2"
      >
        <v-dialog
          ref="end_menu"
          v-model="end_menu"
          :return-value.sync="end_date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="end_date"
              label="Tanggal Akhir"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="end_date"
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="end_menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.end_menu.save(end_date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="2"
      >
        <v-select
          v-model="type"
          label="Type"
          :items="type_items"
          item-value="id"
          item-text="name"
          required
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="2"
      >
        <v-select
          v-model="status"
          label="Status"
          :items="status_items"
          item-value="id"
          item-text="name"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="2"
      >
        <v-btn
          float-left
          left
          width="100%"
          class="ma-2"
          color="primary"
          @click="getResults"
        >
          <span class="mr-1">
            <v-icon light>mdi-filter-outline</v-icon>
          </span>
          Filter
        </v-btn>
      </v-col>
    </v-row>
    <!--  -->
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="12"
      >
        <base-material-card
          title="Transaksi"
          icon="mdi-shopping"
          color="primary"
          class="px-6 py-3"
        >
          <template>
            <div
              v-if="isLoading"
              class="text-center mt-5 mb-5"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </div>
            <div v-else>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        #
                      </th>
                      <th class="text-left">
                        Kode
                      </th>
                      <th class="text-left">
                        Komunitas
                      </th>
                      <th class="text-left">
                        Nama
                      </th>
                      <th class="text-left">
                        Produk
                      </th>
                      <th class="text-left">
                        Harga Beli
                      </th>
                      <th class="text-left">
                        Harga Jual
                      </th>
                      <th class="text-left">
                        Diskon
                      </th>
                      <th class="text-left">
                        Profit
                      </th>
                      <th class="text-left">
                        Tanggal Trx
                      </th>
                      <th class="text-left">
                        Status
                      </th>
                      <th class="text-left">
                        Aksi
                      </th>
                    </tr>
                  </thead>
                  <!--  -->
                  <tbody>
                    <tr
                      v-for="(transaksi, index) in Transaksi.data"
                      :key="transaksi.id"
                    >
                      <td>{{ index + Transaksi.meta.from }}</td>
                      <td>{{ transaksi.code }}</td>
                      <td>
                        {{
                          transaksi.user.community
                            ? transaksi.user.community.name
                            : '-'
                        }}
                      </td>
                      <td>{{ transaksi.user ? transaksi.user.name : '-' }}</td>
                      <td>
                        {{ transaksi.product ? transaksi.product.code : '-' }}
                      </td>
                      <td>
                        {{ transaksi.buy_price }}
                      </td>
                      <td>
                        {{ transaksi.price }}
                      </td>
                      <td>
                        {{ transaksi.discount }}
                      </td>
                      <td>
                        {{
                          transaksi.price -
                            transaksi.buy_price -
                            transaksi.discount
                        }}
                      </td>
                      <td>{{ transaksi.created_at | moment('D MMM YYYY') }}</td>
                      <td tyle="text-transform: capitalize">
                        {{ transaksi.status }}
                      </td>
                      <td>
                        <router-link to="#">
                          <v-icon>mdi-trash-can</v-icon>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="text-center">
                <v-pagination
                  v-model="page"
                  :length="Transaksi.meta.last_page"
                  total-visible="5"
                  @input="getResults"
                />
              </div>
            </div>
          </template>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'Anggota',
    data () {
      return {
        community_id: this.$route.query.community_id || null,
        start_menu: false,
        end_menu: false,
        start_date: this.$route.query.start_date || null,
        end_date: this.$route.query.end_date || null,
        loading4: false,
        status: this.$route.query.status || null,
        Transaksi: {
          data: {},
          meta: {},
        },
        Sumarry: {
          total: 0,
          now: 0,
          success: 0,
          failed: 0,
        },
        community: [
          { name: 'Florida', abbr: 'FL', id: 1 },
        ],
        status_items: [
          {
            id: 'pending',
            name: 'Menunggu',
          },
          {
            id: 'success',
            name: 'Sukses',
          },
          {
            id: 'failed',
            name: 'Gagal',
          },
        ],
        type_items: [
          {
            id: 'pra',
            name: 'Prabayar',
          },
          {
            id: 'pasca',
            name: 'Pascabayar',
          },
          {
            id: 'topup',
            name: 'Top Up',
          },
        ],
        // picker: new Date().toISOString().substr(0, 10),
        isLoading: false,
        isLoadingCommunity: false,
        page: parseInt(this.$route.query.page) || 1,
        q: this.$route.query.q || '',
        type: this.$route.query.type || '',
        community_search: '',
        community_page: 1,
      // per_page: this.$route.query.per_page || 10,
      // offset: this.$route.query.offset || 10,
      // filter_new: this.$route.query.filter_new || 1,
      }
    },
    watch: {
      page (newVal) {
        this.$router.push({ query: { ...this.$route.query, page: newVal } })
      },
      '$route.query.page': function (val) {
        this.page = parseInt(val)
      },
      type (newVal) {
        this.$router.push({ query: { ...this.$route.query, type: newVal } })
      },
      '$route.query.type': function (val) {
        this.type = val
      },
      community_id (newVal) {
        this.$router.push({ query: { ...this.$route.query, community_id: newVal } })
      },
      '$route.query.community_id': function (val) {
        this.community_id = parseInt(val)
      },
      status (newVal) {
        this.$router.push({ query: { ...this.$route.query, status: newVal } })
      },
      '$route.query.status': function (val) {
        this.status = val
      },
      start_date (newVal) {
        this.$router.push({ query: { ...this.$route.query, start_date: newVal } })
      },
      '$route.query.start_date': function (val) {
        this.status_date = val
      },
      end_date (newVal) {
        this.$router.push({ query: { ...this.$route.query, end_date: newVal } })
      },
      '$route.query.end_date': function (val) {
        this.end_date = val
      },
      q (newVal) {
        this.$router.push({ query: { ...this.$route.query, q: newVal } })
      },
      '$route.query.q': function (val) {
        this.q = val
      },
      community_search (val) {
        // Items have already been loaded
        // if (this.community.length > 0) return

        // Items have already been requested
        if (this.isLoadingCommunity) return

        // this.isLoading = true
        this.getCommunity()
      },
    },
    mounted () {
      this.getSummary()
      this.getResults()
      this.getCommunity()
    },
    methods: {
      // setFilter (val) {
      //   this.filter_new = val
      // },
      setTransaksi (data) {
        this.Transaksi = data
      },
      setSummary (data) {
        this.Sumarry = data
      },
      getSummary (data) {
        axios.get('/v1/transaction/summary').then(response => {
          this.setSummary(response.data.data)
        })
      },
      // Search () {
      //   if (this.timer) {
      //     clearTimeout(this.timer)
      //     this.timer = null
      //   }
      //   this.timer = setTimeout(() => {
      //     this.getResults()
      //   }, 700)
      // },
      getResults (page = this.page) {
        this.isLoading = true
        const params = {
          q: this.q,
          page: this.page,
          entities: 'results,user.community,user.region,product',
          start_date: this.start_date,
          end_date: this.end_date,
          type: this.type,
          status: this.status,
          offset: 10,
        }
        axios.get('/v1/transaction?', { params: params }).then(response => {
          this.setTransaksi(response.data)
          this.isLoading = false
        })
      },
      setKomunitas (data) {
        this.community = data.data
      },
      getCommunity (page = 1) {
        this.isLoadingCommunity = true
        const params = {
          sort: '-id',
          page: this.community_page,
          offset: 10,
          q: this.community_search,
        }
        axios
          .get('/v1/community', {
            params: params,
          })
          .then(response => {
            this.setKomunitas(response.data)
            this.isLoadingCommunity = false
          })
          .catch(E => {
            console.error(E)
          })
      },
    },
  }
</script>
